import React from 'react'
import BotaoTornarBorboleta from '../components/BotaoTornarBorboleta'


const Page10 = () => {
    return (
        <div className='bg-offwhite p-4'>
            <p className='font-title font-bold text-center text-2xl'>COMECE AGORA E TORNE-SE UMA  BORBOLETA</p>
            <div className='flex flex-col text-center '>
                <p className='font-title font-bold my-8 text-xl'>ASSINATURA ANUAL</p>
                <p className='line-through text-red-500'>12X R$166,41 ou à vista por R$1.997</p>
                <p className='text-2xl'>APENAS HOJE!</p>
                <p className='text-3xl'>12X R$69,90</p>
                <p className=''>Ou à vista por R$697,00</p>
                <div className='mx-auto'>
                    <ul className='text-left p-4 flex flex-col gap-3'>
                        <CheckLi>
                            Passo a passo para reprogramar sua mente
                        </CheckLi>
                        <CheckLi>Como sair da escassez e virar a chave para viver a abundância destinada a você</CheckLi>
                        <CheckLi>6 encontros ao vivo comigo de acompanhamento, para usar a minha mente para resolver os seus problemas e criar a sua realidade</CheckLi>
                        <CheckLi>Acesso a mais de 70 aulas gravadas</CheckLi>
                        <CheckLi>Comunidade secreta de alunas no telegram</CheckLi>
                        <CheckLi>Direcionamento para sair das dívidas</CheckLi>
                    </ul>
                </div>
                {/* <div className='container'>
                    <p className='text-2xl font-title'>Bônus</p>
                    <ul className='text-left p-4 flex flex-col gap-3'>
                        <CheckCloudLi>Meditação</CheckCloudLi>
                        <CheckCloudLi>Finanças</CheckCloudLi>
                        <CheckCloudLi>Saúde energética</CheckCloudLi>
                        <CheckCloudLi>Comunidade secreta de alunas no Telegram</CheckCloudLi>
                        <CheckCloudLi>Aulas com convidados</CheckCloudLi>
                    </ul>
                </div> */}

                <BotaoTornarBorboleta />
                <p className='text-center text-lg my-1'>COMPRA 100% SEGURA!</p>
            </div>
        </div>
    )
}

const CheckLi = ({ children }) => <li className='flex gap-2'>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    </div>

    <p>{children}</p>

</li>


export default Page10