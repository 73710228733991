import React from 'react'

const Page6 = () => {
  return (
    <div className='bggrdlight'>
        <p className='font-title text-2xl font-bold text-center p-4'>ASSISTA DE ONDE QUISER E EM QUALQUER DISPOSITIVO</p>
        <p className='p-6 text-center'>A comunidade é 100% online, e você poderá acessar a hora que quiser, e de qualquer lugar por meio de um notebook, computador, ou celular com conexão à internet.</p>
    </div>
  )
}

export default Page6