import React from 'react'
import Item from '../components/Item'
import BotaoTornarBorboleta from '../components/BotaoTornarBorboleta'

const aprender = [
    'Aprenderá a transformar e criar sua  própria realidade.',
    'Descobrirá como construir relacionamentos significativos e autênticos, e a estabelecer limites saudáveis, comunicar-se efetivamente e atrair parceiros que valorizem sua verdadeira essência.',
    'Como utilizar as leis universais a seu favor despertando a sua consciência.',
    'E principalmente, irá deixar para trás a antiga eu para se transformar na sua melhor versão,vivendo seus sonhos e propósito sem falta.'
]


const Page8 = () => {
  return (
    <div className='flex flex-col p-4 gap-4 bggrdlight'>
        <p className='text-center text-2xl font-title font-bold'>O QUE VOCÊ IRÁ APRENDER NO MÉTODO EB</p>
        <div className='flex flex-col gap-3'>
            <p>Aprenderá a transformar e criar sua  própria realidade.</p>
            <p>Descobrirá como sair das dívidas e começar a criar uma realidade abundante.</p>
            <p>Como utilizar as leis universais a seu favor despertando a sua consciência.</p>
            <p>E principalmente, irá <b>deixar para trás</b> a antiga eu para se transformar na sua melhor versão,vivendo seus sonhos e propósito sem falta.</p>
        </div>
        <BotaoTornarBorboleta />
    </div>
  )
}

export default Page8