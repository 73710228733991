import React from 'react'

const Page7 = () => {
    return (
        <div className='p-6 bggrddark flex flex-col gap-4 text-lg'>
            <p>
                Você irá descobrir o potencial da transformação pessoal e superar a escassez financeira, enquanto constrói relacionamentos saudáveis e plenos.</p>
            <p>Irá entender a importância de se conectar com algo maior e encontrar um propósito significativo em sua vida.</p>
            <p> Sinta o poder da sua essência e torne-se a melhor versão de si mesmo.
            </p>
            <p>É hora de viver uma vida abundante e cheia de significado.</p>
        </div>
    )
}

export default Page7