import React from 'react'

const Page12 = () => {
  return (
    <div className='min-h-screen flex flex-col p-4 bggrdlight gap-4 font-causten'>
      <p className='font-title text-2xl md:text-4xl uppercase'>Quem é <b className='text-3xl md:text-5xl'>LARA BRASIL</b></p>
      <div className='flex flex-col md:flex-row gap-2'>
        <div className='basis-1/2'>
          <p className='text-md md:text-lg lg:text-xl p-4 bg-gradient-to-b from-zinc-900 to-zinc-700 my-2 rounded-xl text-offwhite'>Meu nome é Lara Brasil, nasci no interior do RS e apesar de aparentemente eu ter uma vida muito boa, internamente minha realidade era outra.
            Passei por momentos muito difíceis na minha vida, a depressão e a ansiedade me atingiram em cheio e eu cheguei a pensar em desistir de tudo diversas vezes, na verdade perdi as contas de quantas vezes tentei tirar minha própria vida.
            E depois de diversas tentativas eu me sentia péssima pois nem acabar com tudo aquilo eu conseguia, foi então que eu mergulhei de cabeça em minha transformação pessoal.
            Passei a estudar muito sobre como mudar minha forma de pensar e de fato descobrir quem eu realmente eu era.
            Eu enfrentei meus medos, superei traumas do passado e aprendi a acreditar em mim mesma.
            Cada obstáculo que eu enfrentei foi uma oportunidade para crescer e me tornar uma pessoa melhor.
          </p>
        </div>
        <div className='basis-1/2 text-center'>
          <img className='mx-auto min-h-96 max-h-[50rem] rounded-lg' alt="sou-lara" src='img/IMG_2348.JPG' />
        </div>
      </div>
      
    </div>
  )
}

export default Page12