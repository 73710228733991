import React, { useState } from 'react'

const depoimentos = [
  {
    src: 'img/dep1.jpeg',
    alt: 'Depoimento 1'
  },
  {
    src: 'img/dep2.jpeg',
    alt: 'Depoimento 2'
  },
  {
    src: 'img/dep3.jpeg',
    alt: 'Depoimento 3'
  },
  {
    src: 'img/dep4.jpeg',
    alt: 'Depoimento 4'
  },
  {
    src: 'img/dep5.jpeg',
    alt: 'Depoimento 5'
  },
  {
    src: 'img/dep6.jpeg',
    alt: 'Depoimento 6'
  },
]

const Page9 = () => {
  const [show, setShow] = useState(0);

  return (
    <section className='flex flex-col bggrddark p-4 w-full items-center gap-4'>
      <p className='text-center text-2xl'>Depoimentos</p>
      <div className='flex flex-row justify-center gap-1'>
        {show > 0 &&<button className='bg-lightblue rounded  hover:bg-darkblue' onClick={() => setShow(show-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>}
        {depoimentos.map((d, i) => i === show && <img src={d.src} alt={d.alt} className='w-72' />)}
        {show < depoimentos.length-1 && <button className='bg-lightblue rounded hover:bg-darkblue' onClick={() => setShow(show+1)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>}
      </div>
    </section>
  )
}

export default Page9