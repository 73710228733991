import React from 'react'

const Page4 = () => {
    return (
        <div className='text-white p-4 bg-gradient-to-br from-darkblue to-lightblue flex flex-col gap-4'>
            <p className='text-center text-xl'>UMA METODOLOGIA DIVIDIDA EM 4 PARTES + <b>BÔNUS</b></p>
            <div className='bg-darkblue  rounded-xl flex flex-col p-2 m-4 gap-4'>
                <p>Essa será a jornada de transformação rumo à sua essência.</p>
                <p>Este não é apenas um curso, mas sim a chave para mudar sua realidade. Prepare-se para revolucionar seu momento atual e iniciar uma jornada de autodescoberta e crescimento.</p>
                <p>Sua transformação pessoal está prestes a começar.</p>
            </div>
        </div>
    )
}

export default Page4