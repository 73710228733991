import React from 'react'
import BotaoGarantirVaga from '../components/BotaoTornarBorboleta'

const Page2 = () => {
    return (
        <div className='font-causten flex flex-col min-h-screen justify-around bg-gradient-to-bl from-darkblue to-lightblue text-offwhite gap-4 p-12'>
            <p className='text-xl md:text-2xl'>Deixe-me revelar algo importante para você: Você é única!
            </p>
            <p className=''>
            Ao longo dos anos, você foi programada a pensar e agir de certa maneira, influenciada por seus pais, professores e pela sociedade. Mas aqui está a grande questão: quem te programou foram pessoas feridas, vítimas de outras vítimas, sem direção e vivendo na escassez.</p>
            <p className=''>
            Você se viu perdendo seu senso de singularidade em meio a uma multidão, com projetos pessoais deixados para trás e uma sensação de estagnação na vida cotidiana. Vivendo apenas para pagar contas e presa em relacionamentos frustrantes.</p>
            <p className=''>
            Mas a boa notícia é que tudo isso pode ser transformado.</p>
            <p>
            Assim como a lagarta passa por mudanças e se prepara para a próxima etapa, você também pode passar por uma profunda metamorfose dentro do seu casulo. 
 É o momento de despertar, tomar consciência de que a mudança começa de dentro para fora.
            </p>
            <p className=''>
            Aqui é onde eu entro para te ajudar a se tornar a borboleta que está pronta para voar, explorar e viver plenamente.</p>
            <p className=''>
            É o momento da transformação, da hora de voar, da liberdade e do momento de conquistar.</p>
            <div className='text-center'>
                <BotaoGarantirVaga />
            </div>
        </div>
    )
}

export default Page2