import { useEffect, useState } from "react";

const Counter = () => {
    const now = new Date();
    const dia20 = new Date(2023, 8, 26)

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    const [isVisible, setIsVisible] = useState(true);

    function calculateTimeRemaining() {
        const timeDiff = dia20 - now;
        if (timeDiff <= 0) {
            // A data dia20 já passou, então retornamos 0 para todos os valores.
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const seconds = Math.floor((timeDiff / 1000) % 60);
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return { days, hours: formattedHours, minutes: formattedMinutes, seconds: formattedSeconds };
    }

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollThreshold = 1000; // Adjust this value as needed
    //         const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    //         setIsVisible(scrollPosition < scrollThreshold);
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timeRemaining]);

    return !isVisible ? null :
        <div
            className="fixed flex pr-10 flex-row-reverse text-center w-full"
        >
            <p className="gap-4 flex font-vt text-xl text-2xl bg-black rounded text-yellow-500 p-2 bg-opacity-60 cursor-wait">
                <p>{timeRemaining.days} dias -</p><p>{timeRemaining.hours}:{timeRemaining.minutes}:{timeRemaining.seconds}</p>
                <img src="img/butterfly.webp" className="h-12 -m-3" />
            </p>
        </div>
}

export default Counter;