import React from 'react'
import BotaoTornarBorboleta from '../components/BotaoTornarBorboleta'

const Page1 = () => {
    return (
        <div className='bg-[url("../public/img/IMG_2354.JPG")] bg-cover bg-no-repeat  min-h-screen'>
            <div className='text-center  bg-offwhite bg-opacity-70 min-h-screen flex flex-col justify-around py-20 px-2'>
                <div className='px-2'>
                    <p className='font-title text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-lightblue to-darkblue '>JORNADA EFEITO BORBOLETA</p>                </div>
                <div>
                    <p className='text-center text-charcoal px-4 font-causten text-2xl'>
                        Reescreva o roteiro da sua vida com a <i>Jornada Efeito Borboleta:</i> Libere sua verdadeira essência, abrace a abundância e manifeste uma realidade extraordinária!
                    </p>
                </div>
                <div className='text-black text-center'>
                    <BotaoTornarBorboleta />
                </div>
            </div>
        </div>
    )
}

export default Page1