import React from 'react'

const BotaoTornarBorboleta = () => {
    return (
        <button className='garantir-vaga border-2 border-yellow-500 shadow-2xl'>
            <a href='https://pay.kiwify.com.br/yThusCg'>
                Quero me tornar uma borboleta!
            </a>
        </button>
    )
}

export default BotaoTornarBorboleta