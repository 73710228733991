import React from 'react'

const Page11 = () => {
    return (
        <div className='bggrddark p-4'>
            <img className='mx-auto h-24 m-4' src="img/garantia.png" alt="7 dias de garantia" />
            <p className='text-justify text-xl p-4'>
                Você terá 7 dias para decidir se o Método Efeito Borboleta é para você!</p>
            <p className='text-justify text-xl p-4'>
                Temos total confiança no resultado do método, por isso, se você não se adaptar, não gostar ou acreditar que não é para você, devolvemos seu dinheiro o mais rápido possível.
            </p>
            <p className='text-justify text-xl p-4'>
                Sem letras pequenas ou enrolação.
            </p>
        </div>
    )
}

export default Page11