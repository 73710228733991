import React from 'react'

const Page13 = () => {
    return (
        <div className='flex flex-col p-4 bggrddark'>
            <p className='font-title text-center text-2xl'>DÚVIDAS FREQUENTES</p>
            <div className='flex flex-col p-4 gap-4'>
                <p className='font-title font-bold text-xl'>Será que o EB é pra mim?</p>
                <p className='bg-lightblue p-1 rounded-lg'>Se você deseja reprogramar a sua mente, encerrar ciclos de escassez, criar sua própria realidade, ser amada, construir uma vida plena, saiba que sim, é para você! O receio no início é normal, mas acredite, essa será a melhor decisão que você tomará, e uma transformação incrível te espera!</p>
                <p className='font-title font-bold text-xl'>O Método é online?</p>
                <p className='bg-lightblue p-1 rounded-lg'>Sim. A comunidade é 100% online, e você poderá acessar a hora que quiser, e de qualquer lugar por meio de um notebook, computador, ou celular com conexão à internet.</p>
                <p className='font-title font-bold text-xl'>Onde receberei o meu acesso?</p>
                <p className='bg-lightblue p-1 rounded-lg'>Após garantir a sua vaga, receberá de forma imediata um e-mail contendo o seu acesso à nossa área de membros e todas as aulas. Você poderá assistir de qualquer aparelho, em qualquer lugar e a qualquer hora.</p>
            </div>
            <div className='text-center'>
                <img className='mx-auto max-h-96' alt='logo' src="img/LOGO.png" />
            </div>
        </div>
    )
}

export default Page13