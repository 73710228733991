import React from 'react'
import BotaoGarantirVaga from '../components/BotaoTornarBorboleta'


const paraMulheresQue = [
    'Querem se livrar da escassez e dos padrões familiares enraizados.',
    "Buscam desenvolver sua mente através da Reprogramação mental.",
    "Desejam sentir-se verdadeiramente completas, amadas, respeitadas e admiradas.",
    "Estão em autodesenvolvimento para se tornarem mulheres que enxergam seu valor e vivem a vida que sempre sonhara, sem problemas financeiros.",
    "Desejam criar a sua própria realidade nos relacionamentos e nas finanças.",
    "Desejam abandonar sua antiga versão, encontrar sua identidade e viver seu propósito",
    "Estão prontas para viver o processo de metamorfose."
]

const Page3 = () => {
    return (
        <div className='bg-gradient-to-b from-offwhite to-beige flex flex-col justify-around p-4 text-lg font-causten gap-6'>
            <p className=' font-title font-bold text-3xl md:text-4xl uppercase text-center' >O MÉTODO EFEITO BORBOLETA É PARA MULHERES QUE:</p>
            <div className='container mx-auto'>
                <ul className='list-disc text-xl md:text-2xl p-4'>
                    {paraMulheresQue.map(p => <li className='my-2'>{p}</li>)}
                </ul>
            </div>
            <div className='flex'>
                <BotaoGarantirVaga />
            </div>
        </div>
    )
}

export default Page3